
export const Reducer = (state, action) => {

    switch (action.type) {
        case "add-all":
            return {
                ...state,
                all: action.payload
            }
        case "add-fruitSalad":
            return {
                ...state,
                fruitSalad: action.payload
            } 
        case "add-smoothieCustom":
            return {
                ...state,
                smoothieCustom: action.payload
            }             
        case "edit-allOrders":
            return {
                ...state,
                allOrders: [...state.allOrders, action.payload],
                
            };
        case "edit-orderType":
            return {
                ...state,
                orderType: [...state.orderType, action.payload]
            }
        case "open-checkout":
            return {
                ...state,
                openCheckout: true
            };
        case "close-checkout":
            return {
                ...state,
                openCheckout: false
            };
        case "edit-checkout":
            return {
                ...state,
                allOrders: [...action.payload]
            };
          
        case "clear-checkout":
            return {
                ...state,
                allOrders: []
            };
        case "juice":
            return {
                ...state,
                isJuiceClicked: true,
                isEnergyClicked: false,
                isDetoxClicked: false,
                isWeightClicked: false,
                isSkinClicked: false,
                isWorkoutClicked: false,
                isInflammatoryClicked: false
            }
        case "energy":
            return {
                ...state,
                isJuiceClicked: false,
                isEnergyClicked: true,
                isDetoxClicked: false,
                isWeightClicked: false,
                isSkinClicked: false,
                isWorkoutClicked: false,
                isInflammatoryClicked: false
            }
        case "detox":
            return {
                ...state,
                isJuiceClicked: false,
                isEnergyClicked: false,
                isDetoxClicked: true,
                isWeightClicked: false,
                isSkinClicked: false,
                isWorkoutClicked: false,
                isInflammatoryClicked: false
            } 
        case "weight":
            return {
                ...state,
                isJuiceClicked: false,
                isEnergyClicked: false,
                isDetoxClicked: false,
                isWeightClicked: true,
                isSkinClicked: false,
                isWorkoutClicked: false,
                isInflammatoryClicked: false
            } 
        case "skin":
            return {
                ...state,
                isJuiceClicked: false,
                isEnergyClicked: false,
                isDetoxClicked: false,
                isWeightClicked: false,
                isSkinClicked: true,
                isWorkoutClicked: false,
                isInflammatoryClicked: false
            } 
        case "workout":
            return {
                ...state,
                isJuiceClicked: false,
                isEnergyClicked: false,
                isDetoxClicked: false,
                isWeightClicked: false,
                isSkinClicked: false,
                isWorkoutClicked: true,
                isInflammatoryClicked: false
            }
        case "inflammatory":
            return {
                ...state,
                isJuiceClicked: false,
                isEnergyClicked: false,
                isDetoxClicked: false,
                isWeightClicked: false,
                isSkinClicked: false,
                isWorkoutClicked: false,
                isInflammatoryClicked: true
            }
        
        default:
            return state;    
    }
}