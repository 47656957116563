import  React, { useRef, useState, useEffect }  from "react";
import "./contact.css";
import Navbar from "../../components/navbar/navbar";
import { axiosInstance } from "../../config";


function Contact(){
    const inputName = useRef();
    const inputContact = useRef();
    const inputDetails = useRef();
    const [submitted, setSubmitted] = useState(false);

    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])

    const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
      const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
      
      const now = new Date();
      let date = now.getDate();
      let day = now.getDay();
      let month= now.getMonth();
      let year = now.getFullYear();
      let hour = now.getHours();
      let minute = now.getMinutes();
  
      let todayDate = days[day] + ", " + date + " " + months[month] + " " + year;
      let todayTime = hour + ":" + minute

    const handleSubmit = async(e)=>{
        e.preventDefault();
        
        const contactInfo = {
            name : inputName.current.value,
            contact : inputContact.current.value,
            information : inputDetails.current.value,
            timeSent: todayTime,
            daySent: todayDate
        }

       
        try {
            let contactPost = await axiosInstance.post("/contact", contactInfo);
            
            if (contactPost.data.status === 'successful') {
                setSubmitted(true);
                

                setTimeout(() => {
                    inputName.current.value = '';
                    inputContact.current.value = '';
                    inputDetails.current.value = '';
                    setSubmitted(false)
                }, 2000);
            }
            
                
            
        } catch (error) {
            console.log(error);
            
        }
    }


   return(<>
          <div className="contact-page">
            <Navbar />
            <div className="contact-info">
                <h2 className="greetings">Hi, my name is <span>Kimani</span>.</h2>

                <div className="contact-details">
                    <p>I am in charge of operations at Blend and Mend. We would be very pleased to hear from you in case of any special orders, complaints, queries, suggestions or compliments.</p>
                    <p>Our contact details are as follows:</p>
                    <p>Phone number:  <span>0704610605</span></p>
                    <p>Email:  <span>kimani@blendandmend.co.ke</span></p>
                    <p>Location:  <span>Along Plainsview road, opposite Nerkwo Restaurant. South B.</span></p>
                    <p>You can also write to us through the contact form below:</p>
                    <form className="contact-form" onSubmit={handleSubmit}>
                     <input className="contact-input" type="text" name="" id="" placeholder="Name" ref={inputName}/>
                     <input className="contact-input" type="text" name="" id="" placeholder="Email or Phone number" ref={inputContact}/>
                     <textarea className="contact-textarea" name="" id="" placeholder="Write your complaint, query, suggestion or compliment here..." ref={inputDetails}></textarea>
                     <button className="contact-button" type="submit">{submitted ? "DONE!! We'll get back to you ASAP." : "Submit"}</button>
                    </form>
                    <p>Don't hesitate to reach out. Our friendly team is here to assist you. We are dedicated to providing an exceptional customer experience to all our customers.</p>
                </div>
            </div>

          </div>
   
   </>

   )
}

export default Contact;